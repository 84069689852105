import {ConfirmDialog} from '@eon.cz/apollo13-frontend-common';
import {FingerprintOutlined} from '@mui/icons-material';
import {Typography} from '@mui/material';
import {useContext} from 'react';
import {FormattedMessage} from 'react-intl';
import {FaceIdIcon} from '../../Common/components/icon/FaceIdIcon';
import {PageContext} from '../../Common/contexts/PageContext';
import {BiometryTypes} from '../../Common/model/MobilePropsModel';
import {sendDataToRN} from '../../Common/services/CommonService';
import {getFromLS, saveToLS} from '../../Common/services/PageService';

type Props = {
    readonly open: boolean;
    readonly onClose: () => void;
};
export const BiometryConfirmationDialog = ({open, onClose}: Props) => {
    const {me} = useContext(PageContext);
    const biometryType = getFromLS('mobile')?.biometryType;
    const handleClickYes = () => {
        saveToLS('mobile', {canUseBiometrics: true});
        sendDataToRN({saveUserId: me?.id});
        onClose();
    };

    const handleClickNo = () => {
        saveToLS('mobile', {canUseBiometrics: false});
        sendDataToRN({saveUserId: 'undefined'});
        onClose();
    };
    return (
        <ConfirmDialog
            open={open}
            onClickYes={handleClickYes}
            onClickNo={handleClickNo}
            description={
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    {biometryType === BiometryTypes.FaceID ? (
                        <FaceIdIcon style={{fontSize: '3rem'}} color="primary" />
                    ) : (
                        <FingerprintOutlined style={{fontSize: '3rem'}} color="primary" />
                    )}
                    <Typography>
                        <FormattedMessage id="mobile.confirmation.text" />
                    </Typography>
                </div>
            }
            loading={false}
        />
    );
};
