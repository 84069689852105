import {gql} from '@apollo/client';

export const DodatkyQueries = {
    gql: {
        dodatky: gql`
            query Dodatky($filter: DodatekPlynFilter!) {
                plyn {
                    dodatky {
                        dodatky(filter: $filter) {
                            cislo
                            cisloSmlouvy
                            datumVytvoreni
                            stav
                            historie {
                                stav
                                datumZmeny
                                idUzivatele
                                uzivatel
                            }
                            prilohy {
                                idRepository
                                id
                                typDokumentu
                                nazev
                                mime
                                delka
                            }
                            podepsal {
                                id
                                jmeno
                                prijmeni
                                telefon
                                podpisDodatku
                            }
                            podepsano
                        }
                    }
                }
            }
        `,
        nastenka: gql`
            query DodatkyNastenka {
                plyn {
                    dodatky {
                        nastenka {
                            dodatkyPodpisStav {
                                kPodpisuAktivni
                            }
                        }
                    }
                }
            }
        `,
    },
};
