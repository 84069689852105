import {SafeFormattedMessage} from '@eon.cz/apollo13-frontend-common';
import {DodatekStav, PozadavekStav, ReklamaceStav, ZadostStav} from '@eon.cz/apollo13-graphql';
import {Grid, Icon, IconButton, Paper, Typography} from '@mui/material';
import * as React from 'react';
import {useState} from 'react';
import {Lang} from '../../../Lang';

interface Props<T> {
    readonly onClick: (stav: T, typ: string | undefined) => () => void;
    readonly stav: T;
    readonly icon: React.ReactNode;
    readonly pocet: string | number | undefined;
    readonly typ?: string;
}

export type PrehledStavu = ZadostStav | ReklamaceStav | DodatekStav | PozadavekStav;
/**
 * Komponenta zobrazující počty v daných stavech
 */
export const PocetStavuWidget = <T extends PrehledStavu>({onClick, stav, icon, pocet, typ}: Props<T>) => {
    const [hovered, setHovered] = useState(false);

    const onHover = () => {
        setHovered(true);
    };

    const onBlur = () => {
        setHovered(false);
    };

    return (
        <Paper
            elevation={0}
            onClick={onClick(stav, typ)}
            onMouseOver={onHover}
            onMouseLeave={onBlur}
            sx={{
                borderRadius: 10,
                '&:hover': {
                    backgroundColor: 'primary.main',
                    color: 'white',
                },
                padding: 1,
                cursor: 'pointer',
                width: '100%',
                backgroundColor: 'inherit',
                border: `1px solid`,
                borderColor: 'primary.main',
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={3} sm={4} lg={5} xl={3}>
                    <IconButton data-testid={`widget-${stav}-button`} aria-label="Forward" color="inherit">
                        <Icon sx={{color: hovered ? 'white' : 'primary.main'}}>{icon}</Icon>
                    </IconButton>
                </Grid>
                <Grid
                    item
                    xs={8}
                    sm={8}
                    lg={7}
                    xl={8}
                    sx={{
                        mr: 'inherit',
                    }}
                >
                    <Typography
                        data-testid={`widget-${stav}-pocet`}
                        sx={{
                            color: hovered ? 'white' : 'primary.main',
                            textAlign: 'right',
                            fontSize: 18,
                        }}
                    >
                        {pocet}
                    </Typography>
                    <Typography variant="button" component="p" sx={{textAlign: 'right', fontSize: 12, color: 'grey.500', lineHeight: 1.5}} color="inherit">
                        <SafeFormattedMessage langArray={Lang} langArrayKey={`WIDGET_STATUS_${stav.toUpperCase()}`} fallbackMessage={stav} />
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};
