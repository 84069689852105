import {gql} from '@apollo/client';

import {CommonFragments} from '../../Common/graphql/CommonFragments';

export const BezproudiQueries = {
    gql: {
        terminyOdstavky: gql`
            query BezproudiTerminyOdstavky($filter: TerminyOdstavkaElektrinaFilter!, $first: Int, $last: Int, $after: Cursor, $before: Cursor) {
                elektrina {
                    bezproudi {
                        terminyOdstavkyMunicipalita(filter: $filter, first: $first, last: $last, after: $after, before: $before) {
                            pageInfo {
                                ...PaginationPageInfo
                            }
                            edges {
                                node {
                                    id
                                    cislo
                                    datumCasDo
                                    datumCasOd
                                    popis
                                    stav
                                }
                            }
                        }
                    }
                }
            }
            ${CommonFragments.paginationPageInfo}
        `,
        terminyOdstavkyZakaznik: gql`
            query BezproudiTerminyOdstavkyZakaznik($filter: OdstavkyZakaznikFilter!, $first: Int, $last: Int, $after: Cursor, $before: Cursor) {
                elektrina {
                    bezproudi {
                        odstavkyZakaznik(filter: $filter, first: $first, last: $last, after: $after, before: $before) {
                            pageInfo {
                                ...PaginationPageInfo
                            }
                            edges {
                                node {
                                    cislo
                                    datumCasDo
                                    datumCasOd
                                    popis
                                    stav
                                    ean
                                    nazev
                                    skutecnyZacatek
                                    skutecnyKonec
                                    id
                                }
                            }
                            totalCount
                        }
                    }
                }
            }
            ${CommonFragments.paginationPageInfo}
        `,

        poruchyVObci: gql`
            query BezproudiPoruchyVObci($filter: PoruchaElektrinaFilter!, $first: Int, $last: Int, $after: Cursor, $before: Cursor) {
                elektrina {
                    bezproudi {
                        poruchy(filter: $filter, first: $first, last: $last, after: $after, before: $before) {
                            pageInfo {
                                ...PaginationPageInfo
                            }
                            edges {
                                node {
                                    cislo
                                    datumCasDo
                                    datumCasOd
                                    kalamita
                                }
                            }
                            totalCount
                        }
                    }
                }
            }
            ${CommonFragments.paginationPageInfo}
        `,

        poruchy: gql`
            query BezproudiPoruchy($first: Int, $last: Int, $after: Cursor, $before: Cursor) {
                elektrina {
                    bezproudi {
                        poruchyVypadky(first: $first, last: $last, after: $after, before: $before) {
                            pageInfo {
                                ...PaginationPageInfo
                            }
                            edges {
                                node {
                                    id
                                    cisloPoruchy
                                    datumCasVznikuPoruchy
                                    ean
                                    komentar
                                    predpokladanyTerminOpravy
                                    vlastniNazevOM
                                }
                            }
                            totalCount
                        }
                    }
                }
            }
            ${CommonFragments.paginationPageInfo}
        `,

        odstavkaDetail: gql`
            query BezproudiOdstavkaDetail($cislo: String!) {
                elektrina {
                    bezproudi {
                        odstavka(cislo: $cislo) {
                            cislo
                            popis
                            terminy {
                                datumCasDo
                                datumCasOd
                                stav
                            }
                            adresy {
                                castObce
                                cisloEvidencni
                                cisloOrientacni
                                cisloPopisne
                                obec
                                psc
                                ulice
                                pocetOm
                            }
                        }
                    }
                }
            }
        `,

        poruchaDetail: gql`
            query BezproudiPoruchaDetail($cislo: String!) {
                elektrina {
                    bezproudi {
                        porucha(cislo: $cislo) {
                            cislo
                            datumCasOd
                            datumCasDo
                            id
                            kalamita
                            adresy {
                                castObce
                                cisloEvidencni
                                cisloOrientacni
                                cisloPopisne
                                obec
                                psc
                                ulice
                                pocetOm
                            }
                        }
                    }
                }
            }
        `,

        bezproudiNastenka: gql`
            query BezproudiNastenka($isMunicipalita: Boolean!) {
                elektrina {
                    bezproudi {
                        nastenka {
                            odstavky {
                                zaplanovanaOdstavkaMunicipalita @include(if: $isMunicipalita)
                                zaplanovanaOdstavkaZakaznik
                            }
                            poruchy {
                                evidovanaPorucha @include(if: $isMunicipalita)
                                evidovanaPoruchaVypadekOm
                            }
                        }
                    }
                }
            }
        `,

        bannery: gql`
            query BezproudiBannery {
                elektrina {
                    bezproudi {
                        bannery {
                            ico
                            banneryDetail {
                                html
                                image
                                height
                                width
                            }
                        }
                    }
                }
            }
        `,
    },
};
