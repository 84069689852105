import {ZadostDavkaStav} from '@eon.cz/apollo13-graphql';
import {bindActionCreators, Dispatch} from '@reduxjs/toolkit';

import {ZadostiMenuItemModel} from '../../model';
import {
    ZadostDavkaElektrinaPoFilterModel,
    ZadostDavkaElektrinaSopFilterModel,
    ZadostElektrinaPoFilterModel,
    ZadostElektrinaRsFilterModel,
    ZadostElektrinaSopFilterModel,
    ZadostElektrinaSopZmenaJisticeFilterModel,
} from '../model';
import {ZadostDavkaElektrinaRsFilterModel} from '../model/ZadostDavkaElektrinaRsFilterModel';
import {
    setResetZadostiElektrinaPODavkyFilter,
    setResetZadostiElektrinaPOZadostiFilter,
    setResetZadostiElektrinaRSDavkyFilter,
    setResetZadostiElektrinaRSZadostiFilter,
    setResetZadostiElektrinaSOPDavkyFilter,
    setResetZadostiElektrinaSOPZadostiFilter,
    setResetZadostiElektrinaSOPZmenaJisticeZadostiFilter,
    setZadostiElektrinaChangePODavkySelectedLeftMenuType,
    setZadostiElektrinaChangePOTab,
    setZadostiElektrinaChangePOZadostiSelectedLeftMenuType,
    setZadostiElektrinaChangeRSDavkySelectedLeftMenuType,
    setZadostiElektrinaChangeRSTab,
    setZadostiElektrinaChangeRSZadostiSelectedLeftMenuType,
    setZadostiElektrinaChangeSOPDavkySelectedLeftMenuType,
    setZadostiElektrinaChangeSOPTab,
    setZadostiElektrinaChangeSOPZadostiSelectedLeftMenuType,
    setZadostiElektrinaChangeSOPZmenaJisticeDavkySelectedLeftMenuType,
    setZadostiElektrinaChangeSOPZmenaJisticeTab,
    setZadostiElektrinaChangeTab,
    setZadostiElektrinaPODavkyiFilter,
    setZadostiElektrinaPOZadostiFilter,
    setZadostiElektrinaRSDavkyFilter,
    setZadostiElektrinaRSZadostiFilter,
    setZadostiElektrinaSOPDavkyFilter,
    setZadostiElektrinaSOPZadostiFilter,
    setZadostiElektrinaSOPZmenaJisticeZadostiFilter,
    setZadostiImportOne,
    setZadostiImportTwo,
} from '../reducers/ZadostiElektrinaReducer';

const ZadostiElektrinaActionDispatch = {
    // Změna záložky v žádostech na elektřině
    changeTab: (tabValue: number) => (dispatch: Dispatch) => dispatch(setZadostiElektrinaChangeTab(tabValue)),

    changeOPTab: (tabValue: number) => (dispatch: Dispatch) => dispatch(setZadostiElektrinaChangePOTab(tabValue)),

    changeRsTab: (tabValue: number) => (dispatch: Dispatch) => dispatch(setZadostiElektrinaChangeRSTab(tabValue)),

    changeSopPrepisTab: (tabValue: number) => (dispatch: Dispatch) => dispatch(setZadostiElektrinaChangeSOPTab(tabValue)),

    changeSopZmenaJisticeTab: (tabValue: number) => (dispatch: Dispatch) => dispatch(setZadostiElektrinaChangeSOPZmenaJisticeTab(tabValue)),

    // Změna stavů v levém menu
    changeRsDavkySelectedLeftMenuType: (type: ZadostDavkaStav) => (dispatch: Dispatch) => dispatch(setZadostiElektrinaChangeRSDavkySelectedLeftMenuType(type)),
    changeRsZadostiSelectedLeftMenuType: (type: ZadostiMenuItemModel) => (dispatch: Dispatch) =>
        dispatch(setZadostiElektrinaChangeRSZadostiSelectedLeftMenuType(type)),

    changePODavkySelectedLeftMenuType: (type: ZadostDavkaStav) => (dispatch: Dispatch) => dispatch(setZadostiElektrinaChangePODavkySelectedLeftMenuType(type)),
    changePOZadostiSelectedLeftMenuType: (type: ZadostiMenuItemModel) => (dispatch: Dispatch) =>
        dispatch(setZadostiElektrinaChangePOZadostiSelectedLeftMenuType(type)),
    changeSopDavkySelectedLeftMenuType: (type: ZadostDavkaStav) => (dispatch: Dispatch) =>
        dispatch(setZadostiElektrinaChangeSOPDavkySelectedLeftMenuType(type)),
    changeSopZadostiSelectedLeftMenuType: (type: ZadostiMenuItemModel) => (dispatch: Dispatch) =>
        dispatch(setZadostiElektrinaChangeSOPZadostiSelectedLeftMenuType(type)),
    changeSopZmenaJisticeZadostiSelectedLeftMenuType: (type: ZadostiMenuItemModel) => (dispatch: Dispatch) =>
        dispatch(setZadostiElektrinaChangeSOPZmenaJisticeDavkySelectedLeftMenuType(type)),

    // Funkce pro nastavení dat z filtrace
    setRsDavkyFilter: (filter: ZadostDavkaElektrinaRsFilterModel) => (dispatch: Dispatch) => dispatch(setZadostiElektrinaRSDavkyFilter(filter)),
    setRsZadostiFilter: (filter: ZadostElektrinaRsFilterModel) => (dispatch: Dispatch) => dispatch(setZadostiElektrinaRSZadostiFilter(filter)),
    setPODavkyFilter: (filter: ZadostDavkaElektrinaPoFilterModel) => (dispatch: Dispatch) => dispatch(setZadostiElektrinaPODavkyiFilter(filter)),
    setPOZadostiFilter: (filter: ZadostElektrinaPoFilterModel) => (dispatch: Dispatch) => dispatch(setZadostiElektrinaPOZadostiFilter(filter)),
    setSOPDavkyFilter: (filter: ZadostDavkaElektrinaSopFilterModel) => (dispatch: Dispatch) => dispatch(setZadostiElektrinaSOPDavkyFilter(filter)),
    setSOPZadostiFilter: (filter: ZadostElektrinaSopFilterModel) => (dispatch: Dispatch) => dispatch(setZadostiElektrinaSOPZadostiFilter(filter)),
    setSopZmenaJisticeZadostiFilter: (filter: ZadostElektrinaSopZmenaJisticeFilterModel) => (dispatch: Dispatch) =>
        dispatch(setZadostiElektrinaSOPZmenaJisticeZadostiFilter(filter)),

    // Funkce pro smazání dat z filtrace
    resetRsDavkyFilter: () => (dispatch: Dispatch) => dispatch(setResetZadostiElektrinaRSDavkyFilter()),
    resetRsZadostiFilter: () => (dispatch: Dispatch) => dispatch(setResetZadostiElektrinaRSZadostiFilter()),
    resetPODavkyFilter: () => (dispatch: Dispatch) => dispatch(setResetZadostiElektrinaPODavkyFilter()),
    resetPOZadostiFilter: () => (dispatch: Dispatch) => dispatch(setResetZadostiElektrinaPOZadostiFilter()),
    resetSOPDavkyFilter: () => (dispatch: Dispatch) => dispatch(setResetZadostiElektrinaSOPDavkyFilter()),
    resetSOPZadostiFilter: () => (dispatch: Dispatch) => dispatch(setResetZadostiElektrinaSOPZadostiFilter()),
    resetSopZmenaJisticeZadostiFilter: () => (dispatch: Dispatch) => dispatch(setResetZadostiElektrinaSOPZmenaJisticeZadostiFilter()),
    setImportElektrinaOne: (value: boolean) => (dispatch: Dispatch) => dispatch(setZadostiImportOne(value)),
    setImportElektrinaTwo: (value: boolean) => (dispatch: Dispatch) => dispatch(setZadostiImportTwo(value)),
};

export const ZadostiElektrinaActionCreator = (dispatch: Dispatch) => bindActionCreators({...ZadostiElektrinaActionDispatch}, dispatch);
