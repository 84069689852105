import {gql} from '@apollo/client';

export const ZadostiElektrinaQueriesNastenka = {
    gql: {
        poctyStavuZadostiElektrina: gql`
            query PoctyStavuZadostiElektrina {
                elektrina {
                    zadosti {
                        nastenka {
                            poctyStavuZadosti {
                                vReseniPreruseni
                                vReseniObnoveni
                                vReseniSopPrepis
                                vReseniSopZmenaJistice
                            }
                        }
                        ramcoveSmlouvy {
                            poctyStavuZadosti {
                                stav
                                pocet
                            }
                        }
                    }
                }
            }
        `,

        poctyStavuZadostiSopElektrina: gql`
            query PoctyStavuZadostiSopElektrina {
                elektrina {
                    zadosti {
                        nastenka {
                            sopPodpis {
                                prepisAktivni
                                prepis
                                zmenaJisticeAktivni
                                zmenaJistice
                            }
                        }
                    }
                }
            }
        `,

        sopPodpisAktivni: gql`
            query PoctyStavuZadostiSopElektrinaAktivni {
                elektrina {
                    zadosti {
                        nastenka {
                            sopPodpis {
                                prepisAktivni
                                zmenaJisticeAktivni
                                zmenaJisticeAktivni
                                zmenaJistice
                            }
                        }
                    }
                }
            }
        `,
    },
};
