import {gql} from '@apollo/client';
import {CommonFragments} from '../../Common/graphql/CommonFragments';

export const PrilohyKeStazeniQueries = {
    gql: {
        sestavy: gql`
            query PrilohyKeStazeniSestavy($filter: PrilohyKeStazeniFilter!) {
                prilohyKeStazeni {
                    sestavy(filter: $filter) {
                        id
                        typ
                        datumUcinnosti
                        nazev
                        mime
                        velikost
                        stav
                        posledniStazeni {
                            byMe
                            stazeno
                            ucet {
                                id
                                admin
                                email
                                jmeno
                                prijmeni
                            }
                        }
                    }
                }
            }
        `,
        danoveDoklady: gql`
            query PrilohyKeStazeniDanoveDoklady($filter: DanovyDokladFiltr!) {
                prilohyKeStazeni {
                    danoveDoklady(filter: $filter) {
                        cisloDokladu
                        typDokladu
                        variabilniSymbol
                        datumVystaveni
                        duzp
                        terminSplatnosti
                        stavDokladu
                        castkaKUhrade
                        uhrazenaCastka
                        ikonyProStazeni {
                            id
                            cisloDokladu
                            komentar
                            nazevSouboru
                            stav
                            posledniStazeni {
                                byMe
                                stazeno
                                ucet {
                                    id
                                    admin
                                    email
                                    jmeno
                                    prijmeni
                                }
                            }
                            prvniStazeni {
                                byMe
                                stazeno
                                ucet {
                                    id
                                    admin
                                    email
                                    jmeno
                                    prijmeni
                                }
                            }
                            repository
                            typPrilohy
                        }
                        upominky {
                            id
                            cisloDokladu
                            komentar
                            nazevSouboru
                            stav
                            posledniStazeni {
                                byMe
                                stazeno
                                ucet {
                                    id
                                    admin
                                    email
                                    jmeno
                                    prijmeni
                                }
                            }
                            prvniStazeni {
                                byMe
                                stazeno
                                ucet {
                                    id
                                    admin
                                    email
                                    jmeno
                                    prijmeni
                                }
                            }
                            repository
                            typPrilohy
                        }
                        splatkovyKalendar {
                            id
                            cisloDokladu
                            komentar
                            nazevSouboru
                            stav
                            posledniStazeni {
                                byMe
                                stazeno
                                ucet {
                                    id
                                    admin
                                    email
                                    jmeno
                                    prijmeni
                                }
                            }
                            repository
                            typPrilohy
                        }
                    }
                }
            }
        `,
        montazniListy: gql`
            query PrilohyKeStazeniMontazniListy($filter: PrilohyKeStazeniFilter!) {
                prilohyKeStazeni {
                    montazniListy(filter: $filter) {
                        id
                        typ
                        eanEic
                        cisloZakazky
                        terminProvedeniSz
                        nazev
                        mime
                        velikost
                        stav
                        posledniStazeni {
                            byMe
                            stazeno
                            ucet {
                                id
                                admin
                                email
                                jmeno
                                prijmeni
                            }
                        }
                    }
                }
            }
        `,
        sestavaTyp: gql`
            query PrilohyKeStazeniSestavaTyp($komodita: Komodita!) {
                prilohyKeStazeni {
                    sestavaTyp(komodita: $komodita) {
                        id
                        klic
                        nazev
                        popis
                        komodita
                    }
                }
            }
        `,
        danovyDokladTyp: gql`
            query PrilohyKeStazeniDanovyDokladTyp {
                prilohyKeStazeni {
                    danoveDokladyTyp {
                        id
                        klic
                        kod
                        nazev
                        popis
                    }
                }
            }
        `,
        danovyDokladRychlyPrehled: gql`
            query PrilohyKeStazeniDanovyDokladRychlyPrehled($komodita: Komodita!) {
                prilohyKeStazeni {
                    danoveDokladyRychlyPrehled(komodita: $komodita) {
                        celkemZbyvaUhraditPoSplatnosti
                        celkemZbyvaUhraditPredSplatnosti
                        celkemZbyvaUhraditTriDnyPoSplatnosti
                    }
                }
            }
        `,
        danoveDokladyNastenka: gql`
            query PrilohyKeStazeniDanovyDokladNastenka($komodita: Komodita!) {
                prilohyKeStazeni {
                    danoveDokladyNastenka(komodita: $komodita)
                }
            }
        `,

        montazniListTyp: gql`
            query PrilohyKeStazeniMontazniListTyp {
                prilohyKeStazeni {
                    montazniListTyp {
                        id
                        klic
                        nazev
                        popis
                    }
                }
            }
        `,

        danovyDokladyZobrazit: gql`
            query PrilohyKeStazeniZobrazitDanoveDoklady($komodita: Komodita!, $sekce: DanovyDokladSekce!) {
                prilohyKeStazeni {
                    danovyDokladZobrazit(komodita: $komodita, sekce: $sekce)
                }
            }
        `,

        technickeDetailyOMElektrina: gql`
            query PrilohyKeStazeniTechnickeDetailyOMPrehledElektrina(
                $before: Cursor
                $after: Cursor
                $first: Int
                $last: Int
                $orderBy: [TechnickeDetailyOrderBy]
            ) {
                elektrina {
                    technickeDetaily {
                        technickeDetailyElektrina(before: $before, after: $after, first: $first, last: $last, orderBy: $orderBy) {
                            pageInfo {
                                ...PaginationPageInfo
                            }
                            edges {
                                node {
                                    id
                                    cislo
                                    nazev
                                    vytvoreno
                                    vytvoril {
                                        id
                                        email
                                        jmeno
                                        prijmeni
                                    }
                                    soubor {
                                        id
                                        typ
                                        nazev
                                        mime
                                        velikost
                                    }
                                }
                            }
                        }
                    }
                }
            }
            ${CommonFragments.paginationPageInfo}
        `,

        technickeDetailyOMPlyn: gql`
            query PrilohyKeStazeniTechnickeDetailyOMPrehledPlyn($before: Cursor, $after: Cursor, $first: Int, $last: Int, $orderBy: [TechnickeDetailyOrderBy]) {
                plyn {
                    technickeDetaily {
                        technickeDetailyPlyn(before: $before, after: $after, first: $first, last: $last, orderBy: $orderBy) {
                            pageInfo {
                                ...PaginationPageInfo
                            }
                            edges {
                                node {
                                    id
                                    cislo
                                    nazev
                                    vytvoreno
                                    vytvoril {
                                        id
                                        email
                                        jmeno
                                        prijmeni
                                    }
                                    soubor {
                                        id
                                        typ
                                        nazev
                                        mime
                                        velikost
                                    }
                                }
                            }
                        }
                    }
                }
            }
            ${CommonFragments.paginationPageInfo}
        `,

        parsedHromadnePodaniDataTechnickeDetailyElektrina: gql`
            query ParsedHromadnePodaniDataTechnickeDetailyElektrina(
                $parsedId: ID!
                $first: Int
                $last: Int
                $before: Cursor
                $after: Cursor
                $filter: HromadnePodaniParseFilter
            ) {
                elektrina {
                    technickeDetaily {
                        parsedHromadnePodaniData(id: $parsedId) {
                            id
                            rows(first: $first, last: $last, before: $before, after: $after, filter: $filter) {
                                edges {
                                    cursor
                                    node {
                                        id
                                        data {
                                            ean
                                        }
                                        validationErrors {
                                            ...ValidationError
                                        }
                                    }
                                }
                                pageInfo {
                                    ...PaginationPageInfo
                                }
                            }
                            statistika {
                                pocetCelkem
                                pocetBezChyb
                                poctyTypuValidacnichChyb {
                                    pocet
                                    typ
                                }
                            }
                            validationErrors {
                                ...ValidationError
                            }
                        }
                    }
                }
            }
            ${CommonFragments.validationError}
            ${CommonFragments.paginationPageInfo}
        `,

        parsedHromadnePodaniDataTechnickeDetailyPlyn: gql`
            query ParsedHromadnePodaniDataTechnickeDetailyPlyn(
                $parsedId: ID!
                $first: Int
                $last: Int
                $before: Cursor
                $after: Cursor
                $filter: HromadnePodaniParseFilter
            ) {
                plyn {
                    technickeDetaily {
                        parsedHromadnePodaniData(id: $parsedId) {
                            id
                            rows(first: $first, last: $last, before: $before, after: $after, filter: $filter) {
                                edges {
                                    cursor
                                    node {
                                        id
                                        data {
                                            eic
                                        }
                                        validationErrors {
                                            ...ValidationError
                                        }
                                    }
                                }
                                pageInfo {
                                    ...PaginationPageInfo
                                }
                            }
                            statistika {
                                pocetCelkem
                                pocetBezChyb
                                poctyTypuValidacnichChyb {
                                    pocet
                                    typ
                                }
                            }
                            validationErrors {
                                ...ValidationError
                            }
                        }
                    }
                }
            }
            ${CommonFragments.validationError}
            ${CommonFragments.paginationPageInfo}
        `,

        parsedHromadnePodaniDataTechnickeDetailyElektrinaRow: gql`
            query ParsedHromadnePodanaDataRowTechnickeDetailyElektrina($rowId: ID!) {
                elektrina {
                    technickeDetaily {
                        parsedHromadnePodaniDataRow(rowId: $rowId) {
                            id
                            data {
                                ean
                            }
                        }
                    }
                }
            }
        `,

        parsedHromadnePodaniDataTechnickeDetailyPlynRow: gql`
            query ParsedHromadnePodanaDataRowTechnickeDetailyPlyn($rowId: ID!) {
                plyn {
                    technickeDetaily {
                        parsedHromadnePodaniDataRow(rowId: $rowId) {
                            id
                            data {
                                eic
                            }
                        }
                    }
                }
            }
        `,
    },
};
