import {Lang} from '../client/Lang';
import IndexPage from '../client/modules/Auth/components/IndexPage';
import {PageContainer} from '../client/modules/Common/containers/PageContainer';

const Index = () => {
    return (
        <PageContainer title={Lang.NASTENKA} bottomNavigation={'dashboard'}>
            <IndexPage />
        </PageContainer>
    );
};

export default Index;
