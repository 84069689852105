import {Div, useMatches} from '@eon.cz/apollo13-frontend-common';
import {ArrowForwardOutlined} from '@mui/icons-material';
import {IconButton, LinearProgress, Paper, Typography} from '@mui/material';
import {ReactNode, useState} from 'react';

type Props = {
    readonly title: string | JSX.Element;
    readonly titleBlockIcon?: string | JSX.Element;
    readonly onClick?: () => void;
    readonly loading?: boolean;
    readonly background?: boolean;
    readonly backgroundDefault?: boolean;
    readonly unlimitedheight?: boolean;
    readonly children: ReactNode;
};

/**
 * Komponenta pro widget nástěnky
 */
export const NastenkaWidget = ({title, titleBlockIcon, loading, onClick, children, background, backgroundDefault, unlimitedheight}: Props) => {
    const matches = useMatches();

    const [elevation, setElevation] = useState(8);
    const [growUp, setGrowUp] = useState(false);
    const handleOnMouseHover = () => {
        setGrowUp(true);
        setElevation(15);
    };
    const handleOffMouseHover = () => {
        setGrowUp(false);
        setElevation(2);
    };
    return (
        <Paper
            elevation={!loading ? elevation : 1}
            onMouseOver={handleOnMouseHover}
            onMouseOut={handleOffMouseHover}
            data-testid={`widget-${title}`}
            sx={(theme) => ({
                ...(growUp &&
                    !loading && {
                        '&:hover': {
                            backgroundColor: theme.palette.grey['100'],
                        },
                    }),
                ...(loading && {
                    opacity: 0.5,
                }),
                ...(background && {
                    backgroundColor: 'secondary.main',
                    background: 'linear-gradient(270deg,rgba(225,48,25,0.86) 0%,#261B62 100%)',
                }),
                ...(backgroundDefault && {
                    backgroundColor: 'background.default',
                }),
                minHeight: matches || unlimitedheight ? 212 : 300,
                height: matches || unlimitedheight ? undefined : 425,
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 5,
                padding: 1,
            })}
        >
            <Div sx={{display: 'flex', alignItems: 'center', padding: '1rem'}}>
                {titleBlockIcon}&nbsp;&nbsp;
                <Typography
                    variant="h6"
                    sx={{
                        ...(background && {
                            color: 'background.default',
                        }),
                    }}
                >
                    {title}
                </Typography>
                <Div sx={{flexGrow: 1}} />
                {onClick ? (
                    <IconButton
                        data-testid={`widget-${title}-button`}
                        sx={{
                            ...(background && {
                                color: 'background.default',
                            }),
                            '&:hover': {
                                backgroundColor: 'secondary.main',
                                color: 'common.white',
                            },
                            width: 30,
                            height: 30,
                        }}
                        onClick={onClick}
                        onTouchStart={onClick}
                        aria-label="Forward"
                    >
                        <ArrowForwardOutlined
                            sx={{
                                ...(background
                                    ? {
                                          color: 'background.default',
                                      }
                                    : {
                                          color: 'primary.main',
                                          '&:hover': {
                                              color: 'common.white',
                                          },
                                      }),
                            }}
                        />
                    </IconButton>
                ) : undefined}
            </Div>
            <Div sx={{padding: 1, overflow: 'auto'}}>
                {loading && <LinearProgress />}
                {!loading && children}
            </Div>
        </Paper>
    );
};
