import * as React from 'react';

import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {FormattedMessage} from 'react-intl';

export interface DetailValueTitleProps {
    readonly title: string;
}

const useStyles = makeStyles(() => ({
    header: {
        lineHeight: 1.5,
    },
}));

/**
 * Komponenta pro nadpis na widgetech
 */
export const DetailTitle: React.FC<DetailValueTitleProps> = ({title}) => {
    const classes = useStyles({});
    return (
        <Typography variant="subtitle2" component="div" className={classes.header}>
            <FormattedMessage id={title} />
        </Typography>
    );
};
