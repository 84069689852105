import {gql} from '@apollo/client';

export const ZadostiPlynQueriesNastenka = {
    gql: {
        poctyStavuZadosti: gql`
            query PoctyStavuZadosti {
                plyn {
                    zadosti {
                        nastenka {
                            poctyStavuZadostiRezervaceDistribucniKapacity {
                                kOpraveAktivni
                                vReseni
                            }
                            poctyStavuZadostiUkonceniPreruseni {
                                vReseniUkonceni
                                vReseniPreruseni
                            }
                            poctyStavuZadostiObnoveni {
                                vReseni
                            }
                            poctyStavuZadostiSopPrepis {
                                vReseni
                            }
                        }
                    }
                }
            }
        `,

        sopPodpisAktivni: gql`
            query SopPodpisAktivni {
                plyn {
                    zadosti {
                        nastenka {
                            sopPodpis {
                                prepisAktivni
                                prepis
                            }
                        }
                    }
                }
            }
        `,

        sopPodpis: gql`
            query SopPrepisAktivni {
                plyn {
                    zadosti {
                        nastenka {
                            sopPodpis {
                                prepis
                                prepisAktivni
                            }
                        }
                    }
                }
            }
        `,
    },
};
