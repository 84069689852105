import {ZadostDavkaStav} from '@eon.cz/apollo13-graphql';
import {bindActionCreators, Dispatch} from '@reduxjs/toolkit';

import {ZadostiMenuItemModel} from '../../model';
import {
    ZadostDavkaPlynObnFilterModel,
    ZadostDavkaPlynRdkFilterModel,
    ZadostDavkaPlynSopFilterModel,
    ZadostDavkaPlynUpFilterModel,
    ZadostPlynObnFilterModel,
    ZadostPlynRdkFilterModel,
    ZadostPlynSopFilterModel,
    ZadostPlynUpFilterModel,
} from '../model';
import {
    setImportOne,
    setResetZadostiPlynOBNDavkyFilter,
    setResetZadostiPlynOBNZadostiFilter,
    setResetZadostiPlynRDKDavkyFilter,
    setResetZadostiPlynRDKZadostiFilter,
    setResetZadostiPlynSOPDavkyFilter,
    setResetZadostiPlynSOPZadostiFilter,
    setResetZadostiPlynUPDavkyFilter,
    setResetZadostiPlynUPZadostiFilter,
    setZadostiPlynChangeOBNSelectedLeftMenuType,
    setZadostiPlynChangeOBNTab,
    setZadostiPlynChangeOBNZadostiSelectedLeftMenuType,
    setZadostiPlynChangeRDKSelectedLeftMenuType,
    setZadostiPlynChangeRDKTab,
    setZadostiPlynChangeRDKZadostiSelectedLeftMenuType,
    setZadostiPlynChangeSOPSelectedLeftMenuType,
    setZadostiPlynChangeSOPTab,
    setZadostiPlynChangeSOPZadostiSelectedLeftMenuType,
    setZadostiPlynChangeTab,
    setZadostiPlynChangeUPSelectedLeftMenuType,
    setZadostiPlynChangeUPTab,
    setZadostiPlynChangeUPZadostiSelectedLeftMenuType,
    setZadostiPlynOBNDavkyFilter,
    setZadostiPlynOBNZadostiFilter,
    setZadostiPlynRDKDavkyFilter,
    setZadostiPlynRDKZadostiFilter,
    setZadostiPlynSOPDavkyFilter,
    setZadostiPlynSOPZadostiFilter,
    setZadostiPlynUPDavkyFilter,
    setZadostiPlynUPZadostiFilter,
} from '../reducers/ZadostiPlynReducer';

const ZadostiPlynActionDispatch = {
    // Změna záložky v žádostech na elektřině
    changeTab: (tabValue: number) => (dispatch: Dispatch) => dispatch(setZadostiPlynChangeTab(tabValue)),
    changeRdkTab: (tabValue: number) => (dispatch: Dispatch) => dispatch(setZadostiPlynChangeRDKTab(tabValue)),
    changeUpTab: (tabValue: number) => (dispatch: Dispatch) => dispatch(setZadostiPlynChangeUPTab(tabValue)),
    changeObnTab: (tabValue: number) => (dispatch: Dispatch) => dispatch(setZadostiPlynChangeOBNTab(tabValue)),
    changeSopTab: (tabValue: number) => (dispatch: Dispatch) => dispatch(setZadostiPlynChangeSOPTab(tabValue)),

    // Změna stavů v levém menu
    changeRdkDavkySelectedLeftMenuType: (type: ZadostDavkaStav) => (dispatch: Dispatch) => dispatch(setZadostiPlynChangeRDKSelectedLeftMenuType(type)),
    changeRdkZadostiSelectedLeftMenuType: (type: ZadostiMenuItemModel) => (dispatch: Dispatch) =>
        dispatch(setZadostiPlynChangeRDKZadostiSelectedLeftMenuType(type)),
    changeUpDavkySelectedLeftMenuType: (type: ZadostDavkaStav) => (dispatch: Dispatch) => dispatch(setZadostiPlynChangeUPSelectedLeftMenuType(type)),
    changeUpZadostiSelectedLeftMenuType: (type: ZadostiMenuItemModel) => (dispatch: Dispatch) =>
        dispatch(setZadostiPlynChangeUPZadostiSelectedLeftMenuType(type)),
    changeObnDavkySelectedLeftMenuType: (type: ZadostDavkaStav) => (dispatch: Dispatch) => dispatch(setZadostiPlynChangeOBNSelectedLeftMenuType(type)),
    changeObnZadostiSelectedLeftMenuType: (type: ZadostiMenuItemModel) => (dispatch: Dispatch) =>
        dispatch(setZadostiPlynChangeOBNZadostiSelectedLeftMenuType(type)),
    changeSopDavkySelectedLeftMenuType: (type: ZadostDavkaStav) => (dispatch: Dispatch) => dispatch(setZadostiPlynChangeSOPSelectedLeftMenuType(type)),
    changeSopZadostiSelectedLeftMenuType: (type: ZadostiMenuItemModel) => (dispatch: Dispatch) =>
        dispatch(setZadostiPlynChangeSOPZadostiSelectedLeftMenuType(type)),

    // Funkce pro nastavení dat z filtrace
    setRdkDavkyFilter: (filter: ZadostDavkaPlynRdkFilterModel) => (dispatch: Dispatch) => dispatch(setZadostiPlynRDKDavkyFilter(filter)),
    setRdkZadostiFilter: (filter: ZadostPlynRdkFilterModel) => (dispatch: Dispatch) => dispatch(setZadostiPlynRDKZadostiFilter(filter)),
    setUpDavkyFilter: (filter: ZadostDavkaPlynUpFilterModel) => (dispatch: Dispatch) => dispatch(setZadostiPlynUPDavkyFilter(filter)),
    setUpZadostiFilter: (filter: ZadostPlynUpFilterModel) => (dispatch: Dispatch) => dispatch(setZadostiPlynUPZadostiFilter(filter)),
    setObnDavkyFilter: (filter: ZadostDavkaPlynObnFilterModel) => (dispatch: Dispatch) => dispatch(setZadostiPlynOBNDavkyFilter(filter)),
    setObnZadostiFilter: (filter: ZadostPlynObnFilterModel) => (dispatch: Dispatch) => dispatch(setZadostiPlynOBNZadostiFilter(filter)),
    setSopDavkyFilter: (filter: ZadostDavkaPlynSopFilterModel) => (dispatch: Dispatch) => dispatch(setZadostiPlynSOPDavkyFilter(filter)),
    setSopZadostiFilter: (filter: ZadostPlynSopFilterModel) => (dispatch: Dispatch) => dispatch(setZadostiPlynSOPZadostiFilter(filter)),

    // Funkce pro smazání dat z filtrace
    resetRdkDavkyFilter: () => (dispatch: Dispatch) => dispatch(setResetZadostiPlynRDKDavkyFilter()),
    resetRdkZadostiFilter: () => (dispatch: Dispatch) => dispatch(setResetZadostiPlynRDKZadostiFilter()),
    resetUpDavkyFilter: () => (dispatch: Dispatch) => dispatch(setResetZadostiPlynUPDavkyFilter()),
    resetUpZadostiFilter: () => (dispatch: Dispatch) => dispatch(setResetZadostiPlynUPZadostiFilter()),
    resetObnDavkyFilter: () => (dispatch: Dispatch) => dispatch(setResetZadostiPlynOBNDavkyFilter()),
    resetObnZadostiFilter: () => (dispatch: Dispatch) => dispatch(setResetZadostiPlynOBNZadostiFilter()),
    resetSopDavkyFilter: () => (dispatch: Dispatch) => dispatch(setResetZadostiPlynSOPDavkyFilter()),
    resetSopZadostiFilter: () => (dispatch: Dispatch) => dispatch(setResetZadostiPlynSOPZadostiFilter()),
    setImportPlynOne: (value: boolean) => (dispatch: Dispatch) => dispatch(setImportOne(value)),
};

export const ZadostiPlynActionCreator = (dispatch: Dispatch) => bindActionCreators({...ZadostiPlynActionDispatch}, dispatch);
