import {WebOutlined} from '@mui/icons-material';
import {Typography} from '@mui/material';
import Router from 'next/router';
import {FormattedMessage} from 'react-intl';
import {PageRoute, RouteService} from '../../../../Common';
import {NastenkaWidget} from '../../../../Nastenka/common/NastenkaWidget';

export const OdplombovaniWidget = () => {
    const onClickOdplombovani = () => Router.push({pathname: RouteService.getPathname(PageRoute.ELEKTRIKARI_ZADATEL)});
    return (
        <NastenkaWidget
            onClick={onClickOdplombovani}
            title={<FormattedMessage id="zadosti.odplombovani.widget.title" />}
            titleBlockIcon={<WebOutlined color="primary" />}
            unlimitedheight
        >
            <Typography component="div" variant="body2" style={{whiteSpace: 'pre-wrap'}}>
                <FormattedMessage id="zadosti.odplombovani.widget.description" />
            </Typography>
        </NastenkaWidget>
    );
};
