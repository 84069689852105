import {AnyAction, Dispatch, PayloadAction} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import {PrilohyKeStazeniFilterModel, PrilohyKeStazeniMenuItemModel} from '../model';
import {
    setChangePrilohyKeStazeniSelectedLeftMenuType,
    setClearPrilohyKeStazeniFilter,
    setPrilohyKeStazeniFilter,
    setPrilohyKeStazeniTab,
} from '../reducers/PrilohyKeStazeniReducer';

export interface PrilohyKeStazeniAction {
    readonly setFilter: (filter: PrilohyKeStazeniFilterModel) => PayloadAction<PrilohyKeStazeniFilterModel>;
    readonly clearFilter: () => AnyAction;
    readonly changeSelectedLeftMenuType: (type: PrilohyKeStazeniMenuItemModel | undefined) => PayloadAction<PrilohyKeStazeniMenuItemModel | undefined>;

    readonly setChangeTab: (tab: number) => PayloadAction<number>;
}

export const usePrilohyKeStazeniActionDispatch = (): PrilohyKeStazeniAction => {
    const dispatch: Dispatch<PayloadAction<PrilohyKeStazeniFilterModel | PrilohyKeStazeniMenuItemModel> | AnyAction> = useDispatch();
    return {
        /**
         * Nastavení filtru pro přílohy ke stažení
         * @param filter
         */
        setFilter: (filter: PrilohyKeStazeniFilterModel) => dispatch(setPrilohyKeStazeniFilter(filter)),

        /**
         * Smazání filtru a nastavení na default hodnoty
         */
        clearFilter: () => dispatch(setClearPrilohyKeStazeniFilter()),

        /**
         * Přepínání stavu v levém menu
         * @param type
         */
        changeSelectedLeftMenuType: (type) => dispatch(setChangePrilohyKeStazeniSelectedLeftMenuType(type)),
        /**
         * Přepínání stavu v levém menu
         * @param type
         */
        setChangeTab: (tab) => dispatch(setPrilohyKeStazeniTab(tab)),
    };
};
